import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _toConsumableArray from "/Users/zhubo/work/lxkj/taxpay-web-admin/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  data: function data() {
    return {
      exclude: ['Yue', 'Invalid']
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    },
    cache: function cache() {
      // console.log(this.$route.meta.noCache)
      return this.$route.meta.noCache;
    },
    excludes: function excludes() {
      return [this.exclude].concat(_toConsumableArray(this.$store.state.setting.exclude));
    }
  },
  watch: {
    $route: {
      handler: function handler(to, from) {
        var _this = this;
        // 监听路由，详情页滚动置顶
        var path = to.path;
        if (path.includes('detail')) {
          this.$nextTick(function () {
            _this.$refs.appMain.scrollTo(0, 0);
          });
        }
      },
      deep: true
    }
  }
};
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.is";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { authorizationValue } from '@/settings';
import store from '@/store/index';
import router from '@/router';
import { getToken, getRefreshToken, getExpireTime } from '@/utils/auth';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import socket from '@/utils/socket';
import { showLoading, hideLoading } from "./loading";
// 请求超时时间，10s
var requestTimeOut = 300 * 1000;
var success = 200;
// 更换令牌的时间区间
var checkRegion = 1200000;
// 提示信息显示时长
var messageDuration = 5 * 1000;

// 系统全局请求对象
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: requestTimeOut,
  responseType: 'json',
  validateStatus: function validateStatus(status) {
    return status === success;
  }
});

// 系统令牌刷新请求对象
var refresh_service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: requestTimeOut,
  responseType: 'json',
  validateStatus: function validateStatus(status) {
    return status === success;
  }
});
service.interceptors.request.use(function (config) {
  var _config = config;
  _config.headers['System-Category'] = '0';
  try {
    var expireTime = getExpireTime();
    if (expireTime) {
      var left = expireTime - new Date().getTime();
      var refreshToken = getRefreshToken();
      if (left < checkRegion && refreshToken) {
        // _config = queryRefreshToken(_config, refreshToken)
      } else {
        if (getToken()) {
          // showLoading()
          _config.headers['Authorization'] = 'bearer ' + getToken();
        }
      }
    }
  } catch (e) {
    hideLoading();
  }
  return _config;
}, function (error) {
  hideLoading();
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (config) {
  hideLoading();
  return config;
}, function (error) {
  hideLoading();
  if (error.response) {
    var errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.message;
    switch (error.response.status) {
      case 404:
        Message({
          message: '很抱歉，资源未找到',
          type: 'error',
          duration: messageDuration
        });
        break;
      case 403:
        Message({
          message: '很抱歉，您暂无该操作权限',
          type: 'error',
          duration: messageDuration
        });
        break;
      case 401:
        if (store.state.account.tokenStatus) {
          socket.close();
          store.commit('account/setTokenStatus', false);
          MessageBox.alert('很抱歉，认证已失效，请重新登录', '温馨提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: function callback(action) {
              window.location.href = '/login';
            }
          });
        }
        break;
      default:
        if (errorMessage === '认证失败' || errorMessage === '刷新令牌已过期，请重新登录' || errorMessage === 'refresh token无效' || errorMessage === '访问令牌不合法') {
          MessageBox.alert('登录已过期，请重新登录', '温馨提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: function callback(action) {
              window.location.href = '/login';
            }
          });
        } else {
          // Message({
          //   message: errorMessage,
          //   type: 'error',
          //   duration: messageDuration
          // })

          MessageBox.alert(errorMessage, '温馨提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: function callback(action) {
              // router.push('/login')
            }
          });
        }
        break;
    }
  }
  return Promise.reject(error);
});
var request = {
  refresh: function refresh(url, params) {
    params['grant_type'] = 'refresh_token';
    return refresh_service.post(url, params, {
      transformRequest: [function (params) {
        return tansParams(params);
      }],
      headers: {
        Authorization: authorizationValue,
        'System-Category': '0'
      }
    });
  },
  login: function login(url, params) {
    params['grant_type'] = 'password';
    return service.post(url, params, {
      transformRequest: [function (params) {
        return tansParams(params);
      }],
      headers: {
        Authorization: authorizationValue
      }
    });
  },
  post: function post(url, params) {
    return service.post(url, params, {
      transformRequest: [function (params) {
        return tansParams(params);
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  },
  postJson: function postJson(url, params) {
    return service.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  put: function put(url, params) {
    return service.put(url, params, {
      transformRequest: [function (params) {
        return tansParams(params);
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  },
  get: function get(url, params) {
    var _params;
    if (Object.is(params, undefined)) {
      _params = '';
    } else {
      _params = '?';
      for (var key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += "".concat(key, "=").concat(params[key], "&");
        }
      }
    }
    return service.get("".concat(url).concat(_params));
  },
  delete: function _delete(url, params) {
    var _params;
    if (Object.is(params, undefined)) {
      _params = '';
    } else {
      _params = '?';
      for (var key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += "".concat(key, "=").concat(params[key], "&");
        }
      }
    }
    return service.delete("".concat(url).concat(_params));
  },
  downFiles: function downFiles(url, params, filename, type) {
    NProgress.start();
    return service.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }).then(function (r) {
      var content = r.data;
      if (content.type === 'application/json') {
        var reader = new FileReader();
        reader.readAsText(content, 'utf-8');
        reader.onload = function (e) {
          var res = JSON.parse(e.target.result);
          console.log(res);
          Message({
            message: res.message,
            type: 'error',
            duration: messageDuration
          });
        };
        return;
      }
      var blob = new Blob([content], {
        type: type
      });
      if ('download' in document.createElement('a')) {
        var elink = document.createElement('a');
        elink.download = filename;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
        Message({
          message: '下载成功',
          type: 'success',
          duration: messageDuration
        });
      } else {
        navigator.msSaveBlob(blob, filename);
      }
      NProgress.done();
    }).catch(function (r) {
      NProgress.done();
      Message({
        message: '下载失败',
        type: 'error',
        duration: messageDuration
      });
    });
  },
  download: function download(url, params, filename) {
    NProgress.start();
    return service.post(url, params, {
      transformRequest: [function (params) {
        return tansParams(params);
      }],
      responseType: 'blob'
    }).then(function (r) {
      var content = r.data;
      // const blob = new Blob([content])
      var blob = content;
      if ('download' in document.createElement('a')) {
        var elink = document.createElement('a');
        elink.download = filename;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, filename);
      }
      NProgress.done();
    }).catch(function (r) {
      console.error(r);
      NProgress.done();
      Message({
        message: '下载失败',
        type: 'error',
        duration: messageDuration
      });
    });
  },
  upload: function upload(url, params) {
    return service.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
function tansParams(params) {
  var result = '';
  Object.keys(params).forEach(function (key) {
    if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
      result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
    }
  });
  return result;
}

// async function queryRefreshToken(config, refreshToken) {
//   const result = await request.refresh('auth/oauth/token', {
//     refresh_token: refreshToken
//   })

//   if (result.status === 'success') {
//     saveData(result.data)
//     config.headers['Authorization'] = 'bearer ' + getToken()
//   }
//   return config
// }

// function saveData(data) {
//   store.commit('account/setAccessToken', data.access_token)
//   store.commit('account/setRefreshToken', data.refresh_token)
//   const current = new Date()
//   const expireTime = current.setTime(current.getTime() + 1000 * data.expires_in)
//   store.commit('account/setExpireTime', expireTime)
// }

export default request;